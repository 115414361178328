import {Route, Routes, useNavigate, useLocation} from "react-router-dom";
import React, { Suspense } from "react";
import UserRoutes from './UserRoutes.jsx';
import UserAdminRoutes from './UserAdminRoutes.jsx';
import AdminRoutes from './AdminRoutes.jsx';

const Guest = React.lazy(() => import("./pages/guest/Guest.jsx"));
const Login =  React.lazy(() => import("./pages/guest/Login.jsx"));
const Logout = React.lazy(() => import("./pages/guest/Logout.jsx"));
import TemplateGuest from "./pages/guest/TemplateGuest.jsx";
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {SidebarProvider} from "./context/SidebarContext.jsx";

function App() {
    history.navigate = useNavigate();
    history.location = useLocation();
    return (
        <Suspense
            fallback={
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }
        >
            <SidebarProvider>
                <Routes>
                    <Route path="/" element={<TemplateGuest content={<Guest />} />} />
                    <Route path="/login" element={<TemplateGuest content={<Login />} />} />
                    <Route path="/logout" element={<Logout />} />
                    {UserRoutes}
                    {UserAdminRoutes}
                    {AdminRoutes}
                </Routes>
            </SidebarProvider>
        </Suspense>
    );
}

export default App;