import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Tab, Tabs, Card } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import { useParams } from 'react-router-dom';
import CompanyCard from "./sections/CompanyCard";
import api from "@/api/api.js";
import { capitalizeFirstLetter} from "@/utils/stringUtils.js";
import CertificateTaxList from "./sections/CertificateTaxList.jsx";
import CertificateFileUpload from "./sections/CertificateFileUpload.jsx";

const DetailCompany = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("constancias");
    const [refreshCertList, setRefreshCertList] = useState(0);
    const { id } = useParams();
    const [company, setCompany] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleUploadSuccess = () => {
        setActiveTab("constancias");
        setRefreshCertList(prev => prev + 1);
    };

    const fetchCompanyData = async () => {
        try {
            const response = await api.get(`/companies/${id}`);
            setCompany(response.data);
        } catch (err) {
            setError("Company not found.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.debug("Calling fetchCompanyData");
        fetchCompanyData();
    }, [id]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
            <Row>
                <Col xs={4}>
                    <CompanyCard company={company} />
                    <CertificateFileUpload companyId={company.id} onUploadSuccess={handleUploadSuccess}  />
                </Col>
                <Col xs={8}>
                    <Card>
                        <Tabs
                            activeKey={activeTab}
                            onSelect={(k) => setActiveTab(k)}
                            id="Tabs-DetailCompany"
                            className="mb-3 text-capitalize-first"
                            justify
                        >
                            <Tab eventKey="constancias" title={capitalizeFirstLetter(t('Constancias'))}>
                                <CertificateTaxList key={refreshCertList} company={company} />
                            </Tab>
                            <Tab eventKey="folios" title={capitalizeFirstLetter(t('Folios'))}>

                            </Tab>
                        </Tabs>
                    </Card>

                </Col>
            </Row>
        </>
    );
};

export default DetailCompany;