import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';

const CompanyCard = ({ company }) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>{company.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{company.businessName}</Card.Subtitle>
            </Card.Body>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <strong>Email:</strong> {company.email}
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>Teléfono:</strong> {company.phone}
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>Tipo:</strong> {company.type}
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>ID Tributaria:</strong> {company.taxIdentification}
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>Folios Comprados:</strong> {company.foliosPurchased}
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>Folios Restantes:</strong> {company.foliosRemaining}
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>Última Actualización:</strong> {company.createdAtAgo}
                </ListGroup.Item>
            </ListGroup>
        </Card>
    );
};

export default CompanyCard;
