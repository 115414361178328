import React, { useEffect, useState, useRef } from 'react';

import CertificateTaxDownload from "./CertificateTaxDownload.jsx";
import api from "@/api/api.js";
import {Table} from "react-bootstrap";

const CertificateTaxList = ({ company }) => {

    const [certificates, setCertificates] = useState([]);

    const fetchListCertificates = async () => {
        try {
            const response = await api.get(`/companies/${company.id}/certificateTax/all`);
            setCertificates(response.data);
        } catch (e) {
            console.error(e);
        }

    };
    useEffect(() => {
        fetchListCertificates()
    }, []);

    return (
        <>
            <Table striped bordered responsive>
                <thead>
                    <tr>
                        <th>Version</th>
                        <th>Desde</th>
                        <th>Fecha Exacta</th>
                        <th>Nombre del archivo</th>
                    </tr>
                </thead>
                <tbody>
                {certificates.map(cert => (
                    <tr key={cert.id}>
                        <td>{cert.version}</td>
                        <td>{cert.createdAtHuman}</td>
                        <td>{ cert.createdAt }</td>
                        <td>
                            <CertificateTaxDownload
                                companyId={company.id}
                                certificateId={cert.id}
                                fileName={cert.originalName}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
}

export default CertificateTaxList;