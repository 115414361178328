import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import api from '@/api/api.js';
import { Alert } from 'react-bootstrap';

const CertificateFileUpload = ({ companyId, onUploadSuccess }) => {
    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length === 0) return;

        const file = acceptedFiles[0]; // Se asume subir un solo archivo
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await api.post(`/companies/${companyId}/certificateTax/latest`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.status === 200 || response.status === 201) {
                onUploadSuccess();
            }
        } catch (err) {
            console.error('Error al subir el archivo:', err);
            // Opcional: podrías manejar un estado de error y mostrarlo
        }
    }, [companyId, onUploadSuccess]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div
            {...getRootProps()}
            className="dropzone mb-3"
            style={{
                border: '2px dashed #ccc',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer'
            }}
        >
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Suelta el archivo aquí...</p>
            ) : (
                <p>Sube una nueva constancia de situación fiscal.</p>
            )}
        </div>
    );
};

export default CertificateFileUpload;
