import React, { useEffect, useRef, useState } from 'react';
import api from "../../../api/api.js";
import $ from 'jquery';
import 'datatables.net'
import languageES from 'datatables.net-plugins/i18n/es-MX';
import CompanyFormModal from "./modal_forms/CompanyFormModal.jsx";
import { Button } from 'react-bootstrap';
import Swal from "sweetalert2";

const Companies = () => {

    const tableRef = useRef(null);
    const dataTable = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);

    const handleOpenModal = (companyId = null) => {
        setSelectedCompanyId(companyId);
        setShowModal(true);
    };

    const handleCloseModal = () =>  {
        setShowModal(false);
        if (dataTable.current) {
            dataTable.current.ajax.reload();
        }
    }

    const handleSave = () => {
        handleCloseModal();
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: "¿Estás seguro?",
            text: "No podrás revertir esta acción.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
            customClass: {
                confirmButton: "btn btn-danger",
            },
        });

        if (result.isConfirmed) {
            try {
                await api.delete(`/companies/${id}`);
                Swal.fire("Eliminado", "La compañía ha sido eliminada.", "success");
                if (dataTable.current) {
                    dataTable.current.ajax.reload();
                }
            } catch (error) {
                console.error("Error deleting company:", error);
                Swal.fire("Error", "No se pudo eliminar la compañía.", "error");
            }
        }
    };

    useEffect(() => {
        dataTable.current = $(tableRef.current).DataTable({
            processing: true,
            serverSide: true,
            language: languageES,
            ajax: async (dataTablesParams, callback) => {
                try {
                    const response = await api.get('/companies', {
                        params: {
                            page: dataTablesParams.start / dataTablesParams.length + 1,
                            itemsPerPage: dataTablesParams.length,
                            search: dataTablesParams.search.value,
                        },
                    });

                    callback({
                        data: response.data['hydra:member'],
                        recordsTotal: response.data['hydra:totalItems'],
                        recordsFiltered: response.data['hydra:totalItems'],
                    });
                } catch (error) {
                    console.error('Error al cargar datos:', error);
                    callback({ data: [] });
                }
            },
            columns: [
                { title: 'Nombre', data: 'name' },
                { title: 'Tipo', render: (data, type, row) => {
                        return `Persona ` + row['type'];
                    }
                },
                { title: 'Folios comprados', data: 'foliosPurchased' },
                { title: 'Folios restantes', data: 'foliosRemaining' },
                {
                    title: 'Acciones',
                    render: function (data, type, row) {
                        const companyId = row['@id'].split('/').pop();

                        return `<button class="btn btn-primary btn-edit-company" data-id="${companyId}">Editar</button>
                               <a href="/${companyId}/detailCompany" class="btn btn-warning btn-detail-company">Detalle</a>
                                <button class='btn btn-danger btn-delete-company' data-id="${companyId}">Borrar</button>
                            `;
                    }
                },
                { title: 'Última actualización', data:  'createdAtAgo' },
            ],
        });
        $(tableRef.current).on('click', '.btn-edit-company', function () {
            const companyId = $(this).data('id');
            handleOpenModal(companyId);
        })

        $(tableRef.current).on('click', '.btn-delete-company', function () {
            const companyId = $(this).data('id');
            handleDelete(companyId);
        })

        return () => {
            if (dataTable.current) {
                dataTable.current.destroy();
            }
        };
    }, []);


    return(
        <>
        <div className="col">
            <div className="card">
                <div className="card-body">
                    <div className="card-title">
                        <h5 className="card-title pb-3">Lista de compañias</h5>
                    </div>
                    <div className={"card-body"}>
                        <table ref={tableRef} className="display" style={{width: '100%'}}></table>
                    </div>
                    <div>
                        <Button onClick={() => handleOpenModal()}>Crear Empresa</Button>

                        <CompanyFormModal
                            show={showModal}
                            handleClose={handleCloseModal}
                            companyId={selectedCompanyId}
                            onSave={handleSave}
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    );

}

export default Companies;