import React, { useState } from 'react';
import { Modal, Button, Form, Col, Row, Alert, Spinner, ProgressBar } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import api from '@/api/api';
import CompanySelector from '@/pages/admin/components/CompanySelector';

const initialFormData = {
    username: "",
    role: "",
    password: "",
    userProfile: {
        name: "",
        lastname: "",
        userType: "contador",
    },
    companies: []
};

const UserFormModal = ({ show, handleClose, onSave }) => {
    const [formData, setFormData] = useState(initialFormData);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    // Función para validar formato de email
    const isValidEmail = (email) => {
        return /^\S+@\S+\.\S+$/.test(email);
    };

    // Calcula la fortaleza de la contraseña (máximo 100)
    const calculatePasswordStrength = (password) => {
        let strength = 0;
        if (password.length >= 6) strength += 20;
        if (/[A-Z]/.test(password)) strength += 20;
        if (/[a-z]/.test(password)) strength += 20;
        if (/\d/.test(password)) strength += 20;
        if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) strength += 20;
        return strength;
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setFormData(prev => ({ ...prev, password: newPassword }));
        const strength = calculatePasswordStrength(newPassword);
        setPasswordStrength(strength);
        if (confirmPassword !== '') {
            setPasswordMatch(newPassword === confirmPassword);
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirm = e.target.value;
        setConfirmPassword(newConfirm);
        setPasswordMatch(formData.password === newConfirm);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        // Si es "name" o "lastname" se actualiza el objeto userProfile
        if (name === "name" || name === "lastname") {
            setFormData(prev => ({
                ...prev,
                userProfile: { ...prev.userProfile, [name]: value }
            }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setConfirmPassword('');
        setPasswordStrength(0);
        setPasswordMatch(true);
        setErrors({});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validar que el username tenga formato de email
        if (!isValidEmail(formData.username)) {
            setErrors(prev => ({ ...prev, username: "El correo no es válido" }));
            return;
        }

        if (!passwordMatch || passwordStrength <= 30) {
            setErrors(prev => ({ ...prev, general: "La contraseña no cumple con los requisitos." }));
            return;
        }

        setLoading(true);
        setErrors({});

        // Generamos un id para el usuario (aunque API Platform genere uno, lo usamos para el payload)
        const userId = uuidv4();
        const userPayload = {
            id: userId,
            username: formData.username,
            password: formData.password,
            roles: formData.role ? [formData.role] : [],
            companies: formData.companies.map(company => ({ id: company.value }))
        };

        try {
            // Crear el usuario
            let userResponse = await api.post('/users', userPayload, {
                headers: { 'Content-Type': 'application/ld+json' }
            });
            // Extraer el IRI del usuario creado (por ejemplo: "/api/users/9dfc2cfb-b8f3-4196-b82c-cb2351f2abd2")
            const userIri = userResponse.data['@id'];

            // Construir el payload del profile usando el IRI del usuario
            const profilePayload = {
                id: uuidv4(),
                name: formData.userProfile.name,
                lastname: formData.userProfile.lastname,
                userType: formData.userProfile.userType,
                user: userIri
            };

            await api.post('/user_profiles', profilePayload, {
                headers: { 'Content-Type': 'application/ld+json' }
            });

            setLoading(false);
            resetForm();
            handleClose();
            if (onSave) onSave();
        } catch (err) {
            setLoading(false);
            if (err.response?.data?.violations) {
                const validationErrors = {};
                err.response.data.violations.forEach(violation => {
                    validationErrors[violation.propertyPath] = violation.message;
                });
                setErrors(validationErrors);
            } else {
                setErrors({ general: "Error inesperado, verifica la conexión o intenta nuevamente." });
            }
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.general && <Alert variant="danger">{errors.general}</Alert>}
                <Form onSubmit={handleSubmit} noValidate>
                    <Form.Group className="mb-3">
                        <Form.Label>Correo del usuario</Form.Label>
                        <Form.Control
                            type="email"
                            name="username"
                            placeholder="Ingresa el correo con el que se loggeará el usuario"
                            value={formData.username}
                            onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                            isInvalid={!!errors.username}
                        />
                        {errors.username && (
                            <Form.Control.Feedback type="invalid">
                                {errors.username}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Ingresa el nombre"
                                    value={formData.userProfile.name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.name}
                                />
                                {errors.name && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Apellido</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastname"
                                    placeholder="Ingresa el apellido"
                                    value={formData.userProfile.lastname}
                                    onChange={handleChange}
                                    isInvalid={!!errors.lastname}
                                />
                                {errors.lastname && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.lastname}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col sm={6}>
                            <Form.Group controlId="password">
                                <Form.Label>Contraseña nueva</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Ingresa tu nueva contraseña"
                                    value={formData.password}
                                    onChange={handlePasswordChange}
                                    isInvalid={!!errors.password}
                                />
                                <ProgressBar
                                    now={passwordStrength}
                                    label={`${passwordStrength}%`}
                                    className="mt-2"
                                />
                                <Form.Text className="text-muted">
                                    La contraseña debe contener al menos 6 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group controlId="confirmPassword">
                                <Form.Label>Confirmar contraseña</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Confirma tu nueva contraseña"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {!passwordMatch && <Alert variant="danger">Las contraseñas no coinciden</Alert>}

                    <Form.Group className="mb-3">
                        <Form.Label>Roles</Form.Label>
                        {["ROLE_USER", "ROLE_USER_ADMIN", "ROLE_ADMIN"].map(roleOption => (
                            <Form.Check
                                key={roleOption}
                                type="radio"
                                label={roleOption}
                                name="role"
                                value={roleOption}
                                checked={formData.role === roleOption}
                                onChange={(e) =>
                                    setFormData({ ...formData, role: e.target.value })
                                }
                            />
                        ))}
                        {errors.role && <Alert variant="danger">{errors.role}</Alert>}
                    </Form.Group>

                    {(formData.role === "ROLE_USER" || formData.role === "ROLE_USER_ADMIN") && (
                        <Form.Group className="mb-3">
                            <Form.Label>Compañías</Form.Label>
                            <CompanySelector
                                onChange={(selected) =>
                                    setFormData({ ...formData, companies: selected || [] })
                                }
                                value={formData.companies}
                            />
                        </Form.Group>
                    )}

                    <Button
                        variant="primary"
                        type="submit"
                        disabled={loading || !passwordMatch || passwordStrength <= 30}
                    >
                        {loading ? <Spinner animation="border" size="sm" /> : 'Guardar Usuario'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default UserFormModal;
