import { Route } from 'react-router-dom';
import { ProtectedRoute } from './routes/ProtectedRoute.jsx';
import Dashboard from "./pages/admin/Dashboard.jsx";
import TemplateAdmin from "./pages/admin/TemplateAdmin.jsx";
import UsersInternal from './pages/admin/pages/UsersInternal.jsx';
import UsersCompanies from './pages/admin/pages/UsersCompanies.jsx';
import Companies from './pages/admin/pages/Companies.jsx';
import DetailCompany from './pages/admin/pages/DetailCompany.jsx';
import UserProfile from './pages/admin/pages/UserProfile.jsx';

const UserRoutes = (
    <Route element={<ProtectedRoute allowedRoles={['ROLE_USER', 'ROLE_ADMIN']} />}>
        <Route path="/my_profile" element={<TemplateAdmin path="/my_profile" title={"User profile"}  content={<UserProfile />} />} />
        <Route path="/dashboard" element={<TemplateAdmin path="/dashboard" title={"Dashboard"}  content={<Dashboard />} />} />
        <Route path="/companies" element={<TemplateAdmin title={"Compañias"} content={<Companies  />} />} />
        <Route path="/:id/detailCompany" element={<TemplateAdmin title={"Detalle de la compañia"} content={<DetailCompany  />} />} />
        <Route path="/users-internal" element={<TemplateAdmin path="/users-internal" title={"Usuarios Internos"} content={<UsersInternal  />} />} />
        <Route path="/users-companies" element={<TemplateAdmin path="/users-companies" title={"Usuarios de compañias"} content={<UsersCompanies/>} />} />
        <Route path="/{id}/users-companies" element={<TemplateAdmin path="/users-companies" title={"Usuarios de compañias"} content={<UsersCompanies/>} />} />
    </Route>
);

export default UserRoutes;
