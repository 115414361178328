import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Dashboard = () => {
    const userRoles = JSON.parse(sessionStorage.getItem('roles')) || [];
    const [companies, setCompanies] = useState([]);

    return(
        <>
            <section className="section dashboard">
                <div className="row">

                </div>

            </section>

        </>
    );
}

export default Dashboard;