import React from 'react';
import api from "@/api/api.js";

const CertificateTaxDownload = ({ companyId, certificateId, fileName = 'archivo.pdf' }) => {
    const handleDownload = async () => {
        try {
            const response = await api.get(
                `/companies/${companyId}/certificateTax/${certificateId}/download`,
                { responseType: 'blob' }
            );
            // Crea un URL a partir del blob recibido
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // Crea un elemento <a> y simula el click para descargar
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            // Limpieza
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error descargando el archivo:', error);
        }
    };

    return (
        <button className="btn btn-primary" onClick={handleDownload}>
            Descargar constancia { fileName }
        </button>
    );
};

export default CertificateTaxDownload;
