import React, { useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import api from '@/api/api';

const CompanySelector = ({ onChange, value }) => {
    // Creamos la función debounced para cargar opciones con un delay de 3000 ms (3 segundos)
    const loadOptions = useMemo(() =>
        debounce((inputValue, callback) => {
            api.get(`/companies?search=${inputValue}`)
                .then(response => {
                    // Extraemos las compañías del array "hydra:member"
                    const companies = response.data["hydra:member"] || [];
                    const options = companies.map(company => ({
                        value: company.id,
                        label: company.name
                    }));
                    callback(options);
                })
                .catch(() => callback([]));
        }, 3000), []); // 3000 ms = 3 segundos

    return (
        <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            isMulti
            onChange={onChange}
            value={value}
            placeholder="Buscar compañías..."
        />
    );
};

export default CompanySelector;
